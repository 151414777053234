html {
    @apply text-gray-900;
}

input[type="range"]::-webkit-slider-container {
    background-color: white;
}

input[type="range"]::-webkit-slider-thumb {
    margin-top: -7px;
    -webkit-appearance: none;
    appearance: none;
    width: 22px;
    height: 22px;
    background: #FFFFFF !important;
    border: 2px solid #f79b3e;
    cursor: pointer;
    border-radius: 30px;
    outline: none;
}

input[type="range"]:disabled::-webkit-slider-thumb {
    cursor: default;
}

input[type="range"]::-webkit-slider-runnable-track {
    height: 0.5rem;
    border-radius: 30px;
    background: linear-gradient(
                    90deg,
                    #0034C3 7.7%,
                    #005BC2 15.4%,
                    #007BBB 23.1%,
                    #0081B5 30.8%,
                    #00A48B 38.5%,
                    #00BB6D 46.2%,
                    #37CB4E 53.9%,
                    #37CB4E 61.6%,
                    #6FCC32 69.3%,
                    #B09F25 77%,
                    #D67F16 84.7%,
                    #EA6B09 92.4%,
                    #ED3F00 100%
    );
}

// React DatePicker
.react-datepicker {
    @apply border border-solid border-gray-200 shadow;

    &__header {
        @apply border-none bg-transparent;
    }

    &__current-month {
        @apply dark:text-gray-900 pt-0.5 pb-2.5;
    }

    &__navigation {
        @apply top-5 text-gray-600 rounded-md dark:text-gray-300 hover:text-gray-900 dark:hover:text-white hover:border-gray-300 dark:hover:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 focus:ring-gray-100 dark:focus:ring-gray-500 transition duration-100 #{!important};

        &--previous {
            @apply left-5;
        }

        &--next {
            @apply right-5;
        }
    }

    &__navigation-icon {
        @apply inset-0;
        @apply before:border-gray-900 #{!important};

        &:before {
            top: 11px;
            bottom: 0;
            left: 0;
            right: 0;
        }

        &--previous {
            &:before {
                left: -3px;
            }
        }

        &--next {
            &:before {
                left: -6px;
            }
        }
    }

    &__month {
        @apply m-0;
    }

    &__day {
        @apply text-gray-600 font-medium rounded-md dark:text-gray-900 hover:border-gray-300 dark:hover:border-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 focus:ring-gray-100 dark:focus:ring-gray-500 transition duration-100;

        &--selected {
            @apply bg-primary-600 #{!important};
        }

        &--today, &--keyboard-selected {
            @apply bg-transparent;
        }

        &--outside-month {
            @apply text-gray-400 #{!important};
        }

        &--disabled {
            @apply text-gray-400 hover:bg-transparent #{!important};
        }
    }

    &-time {
        &__input-container {
            @apply flex-grow;
        }

        &__input {
            @apply w-full m-0 #{!important};

            & input {
                @apply w-full #{!important};
            }
        }

        &__caption {
            @apply dark:text-gray-900 font-medium;
        }
    }

    &__input-time-container {
        @apply m-0 flex items-center gap-2 pt-2.5;
    }
}
